<template>
    <div>
        <div id="content" style="margin-bottom: 0px;" v-if="allCategories">
            <div class="content-wrap">
                <div class="container bottommargin-lg clearfix">
                    <ul class="portfolio-filter clearfix" data-container="#portfolio">
                        <li :class="{'activeFilter': !$route.query.category_id}"><router-link :to="{name: 'Home', query: {}}">All</router-link></li>
                        <template v-for="(category, index) in categories">
                            <li :class="{'activeFilter': (category == $route.query.category_id)}" :key="index"><router-link :to="{path: '/', query: {category_id: category}}">{{getCat(category).title}}</router-link></li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div>
            <div class="past-events-container" v-if="mediaItem">
                <template v-for="(event, index) in showItem()" >
                    <div v-if="event.assets.length>0" class="event" :key="index">
                        <div class="event-poster"
                             v-bind:class="{ 'd-none' : (altPreview === event.id)}"
                             v-bind:style="{ backgroundImage: 'url(' +getPoster(event.assets)+ ')'}"
                             @mouseover.self="e =>  onHover(e, event)"
                             @mouseleave.self="e =>  onLeave(e, event)">
                            <router-link class="action-button" :to="{name:'watch', params: {id: event.id}}">
                                <i class="fas fa-play"></i>
                            </router-link>
                        </div>
                        <div v-bind:style="{ backgroundImage: 'url(' +getAltPreview(event.assets)+ ')'}" class="alt-animated-bg alt-animation"
                             @mouseleave="altPosterLeave()"
                              :class="{ 'd-none' : !(altPreview === event.id)}">
                            <router-link class="action-button" :to="{name:'watch', params: {id: event.id}}">
                                <i class="fas fa-play"></i>
                            </router-link>
                        </div>
                        <div class="event-footer">
                            <span class="event-title">{{ event.title}}</span>
                            <span v-if="allCategories && event.vod_media_category_id" class="event-category">{{getCat(event.vod_media_category_id).title}}</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapActions} from "vuex";
    import noimage from "../../assets/img/no-image.jpg";

    export default {
        name: "HomeScreen",
        data() {
            return {
                mediaItem: null,
                categories: null,
                allCategories: null,
                altPreview: 0,
            }
        },
        methods: {
            ...mapActions(['GET_VOD_CATEGORIES', 'GET_VOD_ITEMS']),
            showItem(){
                if(this.$route.query.category_id){
                    return this.mediaItem.filter(item => item.vod_media_category_id == this.$route.query.category_id);
                }else {
                    return this.mediaItem;
                }

            },
            getItems() {
                this.GET_VOD_ITEMS({limit: 100}).then((data) => {
                    this.mediaItem = data.data.filter((video) => {
                        if(video.assets.find(item => item.type == 'video')) {
                            return video.assets.find(item => item.type == 'video')
                        }
                    });

                    this.categories = this.getAllCategory;

                });
                this.GET_VOD_CATEGORIES({limit: 100}).then((data) => {
                    this.allCategories = data.data;
                })
            },
            getAltPreview(event){
                if (event.find(item => item.type == 'preview_alt')) {
                    let poster = event.find((item) => {
                        return item.type === 'preview_alt'
                    });
                    return this.getImagePath(poster.path)
                }
            },
            onHover(e, event) {
                if (!this.isSafari) {
                    if (event.assets.find(item => item.type == 'preview')) {
                        let poster = event.assets.find((item) => {
                            return item.type === 'preview'
                        });
                        e.target.style.backgroundImage = 'url(' + this.getImagePath(poster.path) + ')'
                    }
                }
                else {
                    this.altPreview = event.id;
                }
            },
            onLeave(e, event) {
                if (!this.isSafari) {
                    if (event.assets.find(item => item.type == 'preview')) {
                        e.target.style.backgroundImage = 'url(' + this.getPoster(event.assets) + ')';
                    }
                }
            },
            getPoster(mediaList) {
                let poster = mediaList.find((item) => {
                    if (item.type === 'image'){
                        return item.path
                    }
                });
                if (!poster) {
                    return noimage
                } else if (typeof poster.path === "string") {
                    return this.getImagePath(poster.path)
                } else if (typeof poster.path === "object") {
                    return this.getImagePath(poster.path[Object.keys(poster.path)[0]])
                } else {
                    return noimage
                }
            },
            getCat(id){
                if(this.allCategories.find(item => item.id == id)){
                    return this.allCategories.find(item => item.id == id)
                }
            },
            altPosterLeave(){
                this.altPreview = 0;
            }
        },
        computed: {
            isSafari(){
                return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                navigator.userAgent &&
                navigator.userAgent.indexOf('CriOS') == -1 &&
                navigator.userAgent.indexOf('FxiOS') == -1;
            },
            getAllCategory() {
                let catArray = [];
                Object.entries(this.mediaItem).forEach(([key, value]) => {
                    catArray.push(value.vod_media_category_id)
                });
                catArray = [...new Set(catArray)];
                return catArray
            },
        },
        created() {
            this.getItems()
        }

    }
</script>

<style scoped lang="scss">
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";

    body{
        background-color: black!important;
    }

    .past-events-container {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        width: fit-content;
        justify-content: space-evenly;
        max-width: 100vw;
        .event {
            transform: scale(0.6);
            margin: -8rem -8rem -4.5rem;
            position: relative;
            @include media-max(md) {
                margin: -8rem auto -4.5rem;
            }
            @include media-min(xl) {
                margin: -8rem -5rem -5rem;
            }


            .event-poster {
                margin: 0 2rem;
                display: flex;
                width: calc(6400px / 10) !important;
                max-width: 150vw;
                position: relative;
                min-height: 480px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                align-items: center;
                justify-content: center;
                border: 1px solid $text-tertiary-middle;
                @include media-max(xl) {
                    margin: 0 1rem;
                }

            }
            .event-footer {
                display: flex;
                flex-direction: column;
                text-align: left;
                color: $text-tertiary-middle;
                font-size: 0.8rem;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.1rem;
                padding: 1.5rem 5rem;
                @include media-max(xl){
                    padding: 1rem 1.2rem;
                }
                .event-title{
                    font-size: 2.4rem;
                }
                .event-category{
                    margin-top: 1rem;
                    font-size: 2rem;
                }
            }
        }

    }

    .poster {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .btn-more {
        color: $text-primary;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.45rem;
        text-align: center;

        box-sizing: border-box;
        height: 3rem;
        width: 13.1rem;
        border: 0.1rem solid $text-primary;
        border-radius: 0.3rem;
    }


    .upcoming-games-text {
        color: $black;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1.75rem;
    }

    .page-title {
        position: relative;
        padding: 50px 0;
        background-color: $card-background;
        border-bottom: 1px solid $text-tertiary-light;
        text-align: left;

        h1 {
            padding: 0;
            margin: 0;
            line-height: 1;
            font-weight: 600;
            letter-spacing: 1px;
            color: $text-tertiary-dark;
            font-size: 1.5rem;
            text-transform: uppercase;
        }

        span {
            display: block;
            margin-top: 10px;
            font-weight: 300;
            color: $text-tertiary-middle;
            font-size: 18px;
        }
    }

    .activeFilter a{
        color: black !important;
        background-color: $accent-warning!important;
        padding: 13px 18px;
        font-weight: bold;
    }

    .portfolio-filter {
        position: relative;
        margin: 1rem 0 3rem 0;
        list-style: none;
        float: left;
        border-radius: 4px;
        padding: 0;

        li {
            float: left;
            position: relative;
            margin: 0.05rem;

            a {
                background-color: $secondary-background;
                display: block;
                position: relative;
                padding: 12px 18px;
                font-size: 13px;
                line-height: 15px;
                color: $text-tertiary-middle;
                border-left: 1px solid rgba($button-primary-rgb, 0.07);
            }
        }

        li:first-child a {
            border-left: none;
            border-radius: 4px 0 0 4px;
        }

        li:last-child a {
            border-radius: 0 4px 4px 0;
        }
    }
    .alt-animated-bg {
        width: calc(6400px / 10) !important;
        position: relative;
        min-height: 480px;
        margin: 0 2rem;
        max-width: 150vw;
        @include media-max(xl) {
            margin: 0 1rem;
        }
    }
    .alt-animation {
        animation: alt-animation-preview-frame 3s steps(10) infinite;
    }
    @keyframes alt-animation-preview-frame {
        from {
            background-position: 0;
        }
        to {
            background-position: 6400px;
        }
    }

    .action-button {
        transform: scale(2);
        z-index: 1;
        border: 1px solid white;
        border-radius: 50%;
        position: absolute;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        color: white;
        top: 45%;
        left: 45%;
        &:hover {
            cursor: pointer;
            color: $text-secondary;
            background-color: rgba($text-tertiary-middle-rgb, .5);
        }
    }
</style>
